@import "../main/abstracts/index";

body[data-template="home"]{

    .section--article-list{

        header{

            h2{

                .subheadline{
                    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 18px, 20px);
                    font-weight: $font__weight--normal;
                    color: $color__black;
                    margin-left: 35px;
                }

                @include media-breakpoint-down(md) {

                    .subheadline{
                        margin-left: 0;
                        margin-top: 6px;
                    }

                    span{
                        display: block;
                    }
                }
            }
        }

        .article-list__list{

            .card{
                border-radius: 8px;
                overflow: hidden;

                @include hover-focus-visible{

                    .card-body .icon{
                        margin: 0;
                        background-color: $color__primary;

                        img{
                            transform: translateX(4px);
                            filter: $filter__white;
                        }
                    }
                }

                @include hover-focus-within{

                    .card-body .icon{
                        margin: 0;
                        background-color: $color__primary;

                        img{
                            transform: translateX(4px);
                            filter: $filter__white;
                        }
                    }
                }

                &:hover,
                &:focus {

                    .card-body .icon{
                        margin: 0;
                        background-color: $color__primary;

                        img{
                            transform: translateX(4px);
                            filter: $filter__white;
                        }
                    }
                }

                &--news{
                    border: none;
                }

                &-header{
                    display: flex;
                    align-items: center;
                    background-color: $color__gray--light;

                    .icon{
                        margin-right: 20px;

                        img{
                            filter: $filter__primary-2;
                        }
                    }

                    time{
                        position: relative;
                        display: inline-block;
                        padding-bottom: 8px;
                        margin-bottom: 5px;
                        font-weight: $font__weight--bold;

                        &:before{
                            position: absolute;
                            display: block;
                            content: "";
                            left: 0;
                            bottom: 0;
                            height: 2px;
                            width: 100%;
                            background-color: $color__font;
                        }
                    }
                }

                &-body{
                    background-color: $color__gray--light;

                    .card-text__info{
                        -webkit-line-clamp: 6;
                        max-height: 165px;
                        margin-top: 0;
                        width: 90%;
                    }

                    .icon{
                        position: absolute;
                        justify-content: center!important;
                        align-items: center;
                        right: 22px;
                        padding: 0!important;
                        height: 36px;
                        width: 36px;
                        border-radius: 50%;
                        background-color: $color__white;

                        img{
                            height: 30px;
                            width: 30px;
                            filter: $filter__primary-2;
                            transition: transform .2s ease-in-out;
                        }
                    }
                }
            }
        }

        .splide:not(.splide--custom){

            .splide__arrow{
                border-color: $color__white;

                &:hover,
                &:focus {
                    border-color: $color__primary;
                }
            }

            .splide__pagination li button{

                &:hover,
                &:focus {

                    &:before{
                        background-color: $color__white;
                        border-color: $color__white;
                    }
                }
            }

            @include media-breakpoint-down(md) {

                .splide__navigation{
                    flex-direction: column;
                }

                .splide__link {
                    margin-top: 2rem;
                }
            }
        }
    }
}
